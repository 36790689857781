import moment from 'moment';

import { UPDATE_BANNER_TIME } from './actions';


const initialState = {
  lastShown: null,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BANNER_TIME:
      return { lastShown: moment.utc().format() };
    default:
      return state;
  }
};
