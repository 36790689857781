import {
  CLOSE_MODAL, SHOW_MODAL,
} from './actions';


const initialState = {
  banner: false,
  notes: null,
  youtubeId: null,
};


export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_MODAL:
      return { ...action, type: undefined };
    case CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
};
