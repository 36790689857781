import PropTypes from 'prop-types';
import { fetchMore } from '../utils/GraphQLList';


export const ARTICLE_SHAPE = PropTypes.shape({
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  translated: PropTypes.shape({
    content: PropTypes.string.isRequired,
    slug: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
});

export const ARTICLE_QUERY = `
id,
date,
translated {
  content,
  slug {
    name
  },
  title
}`;

export const ARTICLE_CONNECTION_QUERY = `
edges {
  node {
    ${ARTICLE_QUERY}
  }
}`;

const ARTICLES_QUERY = `
query Articles($count: Int, $cursor: String, $language: Language!) {
  allArticles(first: $count, after: $cursor, language: $language) {
    pageInfo {
      hasNextPage
      endCursor
    },
    ${ARTICLE_CONNECTION_QUERY}
  }
}`;

export const fetchMoreArticles = params => fetchMore({
  resultCallback: res => res.allArticles,
  query: ARTICLES_QUERY,
  ...params,
});
