import { ARTICLE_QUERY } from '../articles/actions';
import request from '../graphql';

// TODO: Duplicated query from albums.js
export const SZEPCEL_QUERY = `
query Szepcel($language: Language!, $articleCount: Int!) {
  szepcelSettings(language: $language) {
    albums(first: $articleCount) {
      edges {
        node {
          date
          photos {
            id,
            defaultAltText
            url
            thumbnailUrl
          }
          translated {
            slug {
              name
            }
            title
          }
        }
      }
    }
    articles(first: $articleCount) {
      edges {
        node {
          ${ARTICLE_QUERY}
          image {
            thumbnailUrl
          }
        }
      }
    }
    buildingState
    buildingStateCompleted
    titleVideo
  }
  donationSettings {
    currentAmount
    maxAmount
    minAmount
    totalAmount
    url
  }
}
`;

export const fetchSzepcel = ({ locale }) => (
  request(SZEPCEL_QUERY, {
    articleCount: 20,
    language: locale,
  }).then(response => ({
    albums: response.szepcelSettings.albums.edges.map(e => e.node),
    articles: response.szepcelSettings.articles.edges.map(e => e.node),
    buildingState: response.szepcelSettings.buildingState,
    buildingStateCompleted: response.szepcelSettings.buildingStateCompleted,
    photos: response.szepcelSettings.album ? response.szepcelSettings.album.photos : [],
    szepcelSettings: response.szepcelSettings,
    donationSettings: response.donationSettings,
  })).catch(() => null)
);


export const CLEAR_PAYPAL = 'CLEAR_PAYPAL';
export const SET_PAYPAL_OK = 'SET_PAYPAL_OK';

export const clearPayPal = () => ({ type: CLEAR_PAYPAL });
export const setPayPalOK = () => ({ type: SET_PAYPAL_OK });
