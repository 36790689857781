import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLocale, getP } from 'redux-polyglot';

export const PolyglotPropTypes = {
  locale: PropTypes.string.isRequired,
  p: PropTypes.shape({
    t: PropTypes.func.isRequired,
  }).isRequired,
};

const connectPolyglot = Component => connect(state => ({
  locale: getLocale(state),
  p: getP(state),
}))(Component);

export default connectPolyglot;
