import _ from 'lodash';
import 'moment/locale/de';
import 'moment/locale/hu';
import { setLanguage as rpSetLanguage } from 'redux-polyglot';

import de from './de';
import en from './en';
import hu from './hu';
import szelcelDe from '../szepcel/locale/de';
import szelcelEn from '../szepcel/locale/en';
import szelcelHu from '../szepcel/locale/hu';

export const getPhrases = locale => {
  switch (locale.toUpperCase()) {
    case 'EN':
      return { ...en, ...szelcelEn };
    case 'DE':
      return _.merge(en, de, szelcelDe);
    default:
      return { ...hu, ...szelcelHu };
  }
};
export const setLanguage = locale => rpSetLanguage(locale, getPhrases(locale));
