import { combineReducers } from 'redux';
import { polyglotReducer } from 'redux-polyglot';

import audioReducer from './audio/reducers';
import bannerReducer from './banner/reducers';
import headerReducer from './header/reducers';
import lightboxReducer from './lightbox/reducers';
import modalReducer from './modal/reducers';
import privacyReducer from './privacy/reducers';
import szepcelReducer from './szepcel/reducers';


const reducer = combineReducers({
  audio: audioReducer,
  banner: bannerReducer,
  cookie: privacyReducer,
  header: headerReducer,
  lightbox: lightboxReducer,
  modal: modalReducer,
  szepcel: szepcelReducer,
  polyglot: polyglotReducer,
});

export default reducer;
