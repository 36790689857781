const phrases = {
  nav: {
    about: 'Projektről',
    goals: 'Céljaink',
    progress: 'Itt tartunk',
    donate: 'Támogatás',
    faq: 'GY.I.K.',
    news: 'Hírek',
  },
  cover: {
    title: 'Mert én újat cselekszem, most kezd kibontakozni...',
    description: 'A szépcél a Székesfehérvári Baptista Gyülekezet építési programja.',
    subtitle: 'A szépcél a <a href="https://szbgy.hu">Székesfehérvári Baptista Gyülekezet</a> építési programja.',
    donate: 'Támogató leszek',
    video: 'Bővítés (videó)',
    panorama: 'Virtuális túra',
  },
  about: {
    title: 'Projektről',
    content: `
      <p>Növekvő közösségünk helyszűke miatt a gyülekezeti ház bővítésébe és újjáépítésébe kezd három lépésben.</p>
      <p>
        Reményeink szerint az így kibővülő imaházunk korszerű otthonunk lesz a következő években.
        A jelenlegi épületet 1981 óta használja közösségünk, amely a rendszerváltást követően több mint kétszeresére növekedett.
        Az imaterem bővítése mellett így szükségessé vált az infrastruktúra korszerűsítése is.
      </p>
      <p>A fizikai építéssel párhuzamosan gyülekezetünk lelki növekedését és missziós munkáját is fontosnak tartjuk.</p>
      <p>
        Hisszük, hogy céljaink megvalósulása környezetünk számára is áldást jelent.
        Az új épület közösségünkön túl városunk szellemi életét és a Széchenyi utcai városképet is gazdagítja majd.
      </p>`,
  },
  goals: {
    title: 'Céljaink',
    item0: {
      title: '1. A kapu',
      content: `
        <p>Az első ütem során a fogadótér szélesítése és a gyülekezeti ház új homlokzata készül el.</p>
        <ul>
          <li>A projekt kezdete: 2019. április 23.</li>
          <li>Várható befejezése: 2024. április</li>
          <li>Várható költsége: 150 millió Ft</li>
        </ul>
        <p>“Maga a menny Istene ad nekünk sikert, és mi az ő szolgáiként kezdjük el az építést.” (Nehémiás 2:20)</p>
        <p>Éveken át tartó tervezés és előkészítés, sokféle nehézség után hálásak vagyunk, hogy elindulhat végre a kivitelezés is.</p>`,
    },
    item1: {
      title: '2. A sátor',
      content: `
        <p>A második ütemben készül el az új 350 ülőhelyes imatermünk. A jelenlegi nagyterem bővítése a szomszédos telek felé történik majd.</p>
        <p>
          A Reménység Házának otthont adó ingatlan (Széchenyi utca 24.) a közelmúltban a gyülekezet tulajdonába került,
          így annak udvara felé bővül majd az imaterem.
        </p>
        <ul>
          <li>A projekt várható kezdete: 2025.</li>
          <li>Várható befejezése: 2027.</li>
          <li>Várható költsége: ??? millió Ft</li>
        </ul>
        <p>
          Falak nélküli város lesz Jeruzsálem, olyan sok ember és állat lesz benne!
          Én magam oltalmazom mindenfelől - így szól az Úr -, mint egy tüzes fal, és ott leszek benne dicsőségesen.
          (Zakariás 2:8-9)
        </p>`,
    },
    item2: {
      title: '3. Az udvar',
      content: `
        <p>
          A projekt harmadik fázisában készül el a mélygarázs, és az udvar is elnyeri új formáját.
          Gyülekezeti házunk belvárosi elhelyezkedése miatt a parkolást a kibővült telken se tudja
          a hatályos jogszabályoknak megfelelően másként megoldani a közösség,
          mint egy mélygarázs építésével.
          A garázs elkészültét követően kerül sor majd a felszíni tereprendezésre.
        </p>
        <ul>
          <li>A projekt várható kezdete: 2028.</li>
          <li>Várható befejezése: 2029.</li>
          <li>Várható költsége: ??? millió Ft</li>
        </ul>`,
    },
  },
  progress: {
    title: 'Itt tartunk',
  },
  donate: {
    title: 'Támogatás',
    content: `
      <p>Minden segítség sokat jelent. Köszönjük, ha adományával Ön is hozzájárul gyülekezeti házunk bővítéséhez!</p>
      <p>Felajánlását az alábbi módokon teheti meg. Kérjük, válasszon!</p>`,
    once: 'Egyszeri',
    emailPlaceholder: 'valami@gmail.com',
    amountPlaceholder: 'A minimum összeg: %{amount} Ft',
    optionalData: 'Opcionálisan megadható adatok:',
    accept: `
      Elfogadom az <a href="https://szbgy.hu/szepcel-adatvedelem" target="_blank">adatvédelmi tájékoztatót</a>
      és a <a href="https://szbgy.hu/szepcel-tamogatasi-feltetelek" target="_blank">támogatási feltételeket</a>.`,
    simplepay: {
      buttonTitle: 'Tovább',
      buttonSubtitle: 'a fizetéshez',
      logoUrl: 'http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf',
      logoTitle: 'SimplePay - Online bankkártyás fizetés',
      logoAlt: 'SimplePay vásárlói tájékoztató',
    },
    input: {
      firstName: 'Keresztnév',
      lastName: 'Vezetéknév',
      country: 'Ország',
      zipCode: 'Irsz.',
      city: 'Város',
      address: 'Cím',
    },
    transfer: {
      content: `
        <ul>
          <li><strong>Számlatulajdonos:</strong></li>
          <li>Székesfehérvári Baptista Gyülekezet</li>
          <li><strong>Számlaszám:</strong></li>
          <li>Unicredit Bank</li>
          <li>10918001-00000062-18340002</li>
        </ul>`,
      title: 'Átutalás',
      subTitle: 'saját bankjánál',
    },
    footer: `
      <p>
        Amennyiben készpénz átutalási megbízáson (sárga csekk) keresztül szeretné támogatni az építkezést,
        kérjük jelezze ezt felénk a +36 20 822 8939 telefonszámon, vagy a baptistafehervar@gmail.com e-mail címen,
        és mi néhány napon belül küldünk Önnek egy postán befizetendő csekket.
      </p>`,
    cancelled: 'Tranzakció megszakítva',
  },
  counter: {
    title: 'Eddigi támogatások összege',
    percent: 'a teljes költség <span>%{percent}%</span> -a',
  },
  faq: {
    item0: {
      question: 'Nincs PayPalom, de havi utalást szeretnék beállítani. Mi a megoldás?',
      answer: `
        <p>
          Rendszeres átutalási megbízást a saját számlavezető bankjánál van lehetősége beállítani.
          Ez történhet bankfiókban, illetve a legtöbb bank már online,
          illetve mobil applikációs felületeket is biztosít az ügyfeleinek a bankfióki ügyintézés helyett.
        </p>`,
    },
    item1: {
      question: 'Cégként is küldhetek pénzt?',
      answer: `
        <p>
          Igen. Mivel a Gyülekezetünk önálló Egyházi Jogi Személy,
          így a nekünk utalt támogatást a cégek költségként, ráfordításként elszámolhatják.
          A támogatásról egy igazolást állítunk ki Önöknek, és azt tudják bizonylatként használni az elszámoláshoz.
        </p>`,
    },
    item2: {
      question: 'Külföldről is tudok utalni?',
      answer: `
        <p>
          Természetesen, az átutalási megbízást a számlavezető bankjánál kezdeményezheti.
          A legtöbb bank továbbá már online, illetve mobil applikációs felületeket is biztosít az ügyfeleinek a bankfióki ügyintézés helyett.
        </p>
        <p>
          A külföldről történő átutaláshoz kérjük használja az alábbi azonosítókat:
        </p>
        <p>
          <ul>
            <li>SWIFT: BCXHUHB</li>
            <li>IBAN: HU81 10918001-00000062-18340002</li>
          </ul>
        </p>`,
    },
    item3: {
      question: 'Van lehetőség Euróban vagy Dollárban utalásra?',
      answer: `
        <p>
          Igen. Ilyen esetekben a számlavezető bank a megfelelő deviza átváltást követően biztosítja számunkra a magyar Forintban (HUF) történő jóváírást.
        </p>`,
    },
    item4: {
      question: 'Az adóm 1%-át fel tudom ajánlani Önöknek?',
      answer: `
        <p>
          Igen, a gyülekezetünk tagjai által létrehozott Reménység Háza Alapítványnak,
          melynek fő tevékenységi köre a Gyermek és Ifjúságvédelem.
          Az alapítvány 90 éves használati joggal rendelkezik az összevont ingatlanon,
          így az építési költségek finanszírozásához is hozzájárulhat.
          Ha valaki az Alapítványnak adja az 1%-os támogatását, azzal segíteni tudja az építkezést.
          A Reménység Háza Alapítvány adószáma: 19098801-1-07
        </p>`,
    },
    item5: {
      question: 'Hogyan kezelik az adataimat?',
      answer: `
        <p>
          A honlap használatával kapcsolatos <a href="https://szbgy.hu/szepcel-adatvedelem" target="_blank">Általános adatvédelmi tájékoztatót</a>,
          illetve támogatás esetén a <a href="https://szbgy.hu/szepcel-tamogatasi-feltetelek" target="_blank">Támogatási szerződési feltételeket</a>
          a dokumentum nevére kattintva érheti el.
        </p>`,
    },
    item6: {
      question: 'Kaphatok számlát a támogatásról?',
      answer: `
        <p>
          Számlát nem tudunk kibocsátani, ezt helyettesíti a „Cégként is küldhetek pénzt?” kérdésnél említett igazolás.
        </p>`,
    },
    item7: {
      question: 'Hogyan mondhatom le a rendszeres havi támogatást?',
      answer: `
        <p>
          <ul>
            <li>
              Rendszeres banki átutalás esetén a számlavezető bankjánál kell kezdeményezni
              a rendszeres átutalási megbízás változtatását, illetve törlését.
              A legtöbb bank már online, illetve mobil applikációs felületeket is biztosít az ügyfeleinek a bankfióki ügyintézés helyett.
            </li>
            <li>
              A rendszeres kártyás fizetéses megbízást véglegesen lemondani,
              vagy szüneteltetni a banki rendszer (SimplePay, PayPal) által
              automatikusan megküldött levélben szereplő hivatkozások segítségével,
              vagy a Székesfehérvári Baptista Gyülekezet e-mail címére (baptistafehervar@gmail.com) küldött kérelemmel lehet.
            </li>
          </li>
        </p>`,
    },
    item8: {
      question: 'Milyen típusú bankkártyával tudok támogatást kezdeményezni?',
      answer: `
        <p>
          A SimplePay és a PayPal internetes fizetési rendszere a Mastercard/Maestro,
          a VISA termékcsaládba tartozó VISA és VISA Electron
          (az Electronnál csak abban az esetben, ha azt a kibocsátó bank engedélyezi)
          bankkártyák és hitelkártyák használatát,
          valamint internetes használatra alkalmas webkártyával való fizetést teszi lehetővé.
        </p>`,
    },
    title: 'Gyakori Kérdések',
  },
  news: {
    continueReading: 'tovább',
    title: 'Aktuális hírek',
  },
  photos: {
    seeMore: 'megnézem',
    title: 'Galéria',
  },
  thanks: {
    title: 'Köszönjük, hogy támogatta az építkezésünket.',
    subtitle: 'Kérem ossza meg, hogy mások is segíthessenek.',
    facebookShare: 'Facebook megosztás',
    aboutUs: 'bővebben a közösségünkről',
    back: 'vissza a támogatás oldalra',
    simplepay: {
      referenceNumber: 'SimplePay referenciaszám',
      orderId: 'Adomány azonosító',
      date: 'Dátum',
      successful: {
        title: 'Sikeres kártya ellenőrzés.',
      },
      unsuccessful: {
        title: 'Sikertelen tranzakció.',
        subtitle: `
          Kérjük, ellenőrizze a tranzakció során megadott adatok helyességét.
          Amennyiben minden adatot helyesen adott meg, a visszautasítás okának kivizsgálása
          kapcsán kérjük, szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.`,
      },
      timeout: {
        title: 'Megszakított tranzakció',
        subtitle: 'Ön megszakította a fizetést, vagy lejárt a tranzakció maximális ideje!',
      },
    },
  },
  szepcel: {
    privacyPolicyUrl: 'https://szbgy.hu/szepcel-adatvedelem',
    title: 'Szépcél',
  },
};

export default phrases;
