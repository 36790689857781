const phrases = {
  nav: {
    about: 'Unser Projekt',
    goals: 'Unsere Ziele',
    progress: 'Aktuelle Lage',
    donate: 'Unterstützung',
    faq: 'F.A.Q.',
    news: 'Nachrichten',
  },
  cover: {
    title: 'Denn ich will etwas Neues tun! Es hat schon begonnen…',
    description: 'Das „szépcél” ist das Bauprojekt der Baptisten-Gemeinde Székesfehérvár.',
    subtitle: 'Das „szépcél” ist das Bauprojekt der <a href="https://szbgy.hu/en">Baptisten-Gemeinde Székesfehérvár</a>.',
    donate: 'Will unterstützen',
    video: 'Erweiterungsbau (Video)',
    panorama: 'Virtuelle Tour',
  },
  about: {
    title: 'Unser Projekt',
    content: `
      <p>Aufgrund des begrenzten Raums unserer wachsenden Gemeinde beginnen wir mit der Erweiterung und Rekonstruktion des Gemeindehauses in drei Schritten.</p>
      <p>
        Wir hoffen, dass das neue Gemeindehaus in den kommenden Jahren zu einem modernen Zuhause wird.
        Das heutige Gebäude wird seit 1981 von unserer Gemeinde verwendet, was sich nach dem Regimewechsel mehr als verdoppelt hat.
        Neben der Erweiterung des Gebetsraumes wurde auch die Modernisierung der Infstrastruktur vonnöten.
      </p>
      <p>Neben dem Prozess der physischen Bauarbeiten halten wir auch das geistliche Wachstum und die Missionsarbeit unserer Gemeinde für wichtig.</p>
      <p>
        Wir glauben daran, dass die Verwirklichung unserer Ziele auch für unsere Umgebung ein Segen ist.
        Das neue Gebäude wird nicht nur unsere Gemeinschaft sondern auch das geistliche Leben der Stadt und das Stadtbild der Szechenyi Straße bereichern.  
      </p>`,
  },
  goals: {
    title: 'Unsere Ziele',
    item0: {
      title: '1. Das Tor',
      content: `
        <p>In der ersten Phase werden die Erweiterung des Empfangsbereichs und die neue Fassade des Gemeindehauses abgeschlossen.</p>
        <ul>
          <li>Projektstart: 23. April 2019</li>
          <li>Voraussichtliche Fertigstellung: 5. Juni 2022</li>
          <li>Voraussichtliche Kosten: 450.000,00 EUR</li>
        </ul>
        <p>“Der Gott des Himmels wird es uns gelingen lassen; darum wollen wir, seine Knechte, uns aufmachen und bauen.” (Nehemiah 2:20)</p>
        <p>Nach jahrelanger Planung, Vorbereitungen, vielerlei Schwierigkeiten sind wir dankbar, dass auch die Ausführung endlich beginnen kann.</p>`,
    },
    item1: {
      title: '2. Das Zelt',
      content: `
        <p>In der zweiten Phase wird unsere neue Gebetshalle mit 350 Sitzplätzen fertiggestellt. Unsere heutige Großhalle wird auf das angrenzende Gelände erweitert.</p>
        <p>
          Das Grundstück der Stiftung Reménységháza, (Széchenyi utca 24) geriet vor Kurzem in den Besitz der Gemeinde,
          daher wird der Gebetsraum auf dessen Innenhof erweitert.
        </p>
        <ul>
          <li>Voraussichtlicher Anfang des Projekts: 2022</li>
          <li>Voraussichtliche Fertigstellung: 2023</li>
          <li>Geschätzte Kosten: ??? Tausend EUR</li>
        </ul>
        <p>
          Als offene Stadt soll Jerusalem bewohnt werden wegen der großen Menge von Menschen und Vieh in seiner   Mitte,
          und ich selbst, spricht der HERR, will eine feurige Mauer um es her sein und Herrlichkeit in seiner Mitte.
          (Sachariah 2:8-9)
        </p>`,
    },
    item2: {
      title: '3. Der Hof',
      content: `
        <p>
          In der dritten Phase des Projekts wird die Tiefgarage fertiggestellt, und auch der Hof wird seine neue Form bekommen. 
          Aufrgund der Innenstadtlage unseres Gemeindehauses kann die Gemeinde die Parkgelegenheiten auf dem erweiterten Grundstück gemäß den geltenden Vorschriften nur durch den Bau einer Tiefgarage schaffen. 
          Nach der Fertigstellung der Garage erfolgt die Geländeregulierung.
        </p>
        <ul>
          <li>Voraussichtlicher Anfang des Projekts: 2024</li>
          <li>Voraussichtliche Fertigstellung: 2025</li>
          <li> Geschätzte Kosten: ??? Tausend EUR</li>
        </ul>`,
    },
  },
  progress: {
    title: 'Aktuelle Lage',
  },
  donate: {
    title: 'Unterstützung',
    content: `
      <p>Jede Hilfe ist willkommen. Vielen Dank für den Beitrag zur Erweiterung unseres Gemeindehauses!</p>
      <p>Sie können uns folgendermaßen unterstützen:</p>`,
    once: 'Einmalige Spende',
    emailPlaceholder: 'etwas@gmail.com',
    amountPlaceholder: 'Mindestbetrag: 6 €',
    optionalData: 'Optionale Daten:',
    accept: `
      Ich akzeptiere die <a href="https://szbgy.hu/szepcel-datenschutz" target="_blank">Allgemeine Datenschutzerklärung</a>
      und die <a href="https://szbgy.hu/einwilligung-zur-datenverwaltung-und-informationen-fur-unterstutzer" target="_blank">Datenverwaltung für Unterstützer</a>.`,
    simplepay: {
      buttonTitle: 'Weiter',
      buttonSubtitle: 'zur Zahlung',
      logoUrl: 'http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf',
      logoTitle: 'SimplePay - Online Zahlung mit Bankkarte',
      logoAlt: 'SimplePay Käuferinformation',
    },
    input: {
      firstName: 'Vorname',
      lastName: 'Nachname',
      country: 'Land',
      zipCode: 'Plz',
      city: 'Stadt',
      address: 'Straße',
    },
    transfer: {
      content: `Kontoinhaber:
        <ul>
          <li><strong>Kontoinhaber:</strong></li>
          <li>Székesfehérvári Baptista Gyülekezet</li>
          <li><strong>Bankverbindung:</strong></li>
          <li>Unicredit Bank</li>
          <li>SWIFT: BCXHUHB</li>
          <li>IBAN: HU81 10918001-00000062-18340002</li>
        </ul>`,
      title: 'Überweisung',
      subTitle: 'bei Ihrer Bank',
    },
    footer: '',
    cancelled: 'Transaktion abgebrochen',
  },
  counter: {
    title: 'Summe der bisherigen Spenden',
    percent: '<span>%{percent}%</span> ganzer Betrag',
  },
  faq: {
    item0: {
      question: 'Ich habe kein PayPal-Konto, würde trotzdem eine monatliche Überweisung einrichten. Was kann ich tun?',
      answer: `
        <p>
          Einen Dauerauftrag können Sie bei Ihrer kontoführenden Bank einrichten.
          Dies kann in einer Bankfiliale erfolgen, bzw. die meisten Banken bieten Ihren Kunden die Möglichkeit eines Zugangs durch Internet, bzw. Smartphone-App zu ihrer Online-Banking an.
        </p>`,
    },
    item1: {
      question: 'Kann ich auch als Unternehmen Geld senden?',
      answer: `
        <p>
          Ja. Da unsere Gemeinde als selbständige kirchliche Rechtsperson geführt wird, kann die uns überwiesene Unterstützung als Kostenaufwand verbucht werden. 
          Dazu erhalten Sie eine Bescheinigung erstellt, die Sie als Quittung für die Abrechnung verwenden können. 
        </p>`,
    },
    item2: {
      question: 'Kann ich aus dem Ausland überweisen?',
      answer: `
        <p>
          Natürlich, Sie können eine Überweisung durch Ihre kontoführende Bank veranlassen.
          Dies kann in einer Bankfiliale erfolgen, bzw. es wird von den meisten Banken die Möglichkeit eines Zugangs durch Internet, bzw. Smartphone-App zu Ihrer Online-Banking angeboten.
        </p>
        <p>
          Um aus dem Ausland zu transferieren verwenden Sie bitte folgende Daten:
        </p>
        <p>
          <ul>
            <li>SWIFT: BCXHUHB</li>
            <li>IBAN: HU81 10918001-00000062-18340002</li>
          </ul>
        </p>`,
    },
    item3: {
      question: 'Ist es möglich in Euro oder Dollar zu überweisen?',
      answer: `
        <p>
          Ja, in solchen Fällen wird eine Gutschrift nach der entsprechenden Währungsumrechnung durch die kontoführende Bank im ungarischen Forint (HUF) gewährt.
        </p>`,
    },
    item4: {
      question: 'Kann ich Ihnen das 1% Prozent meiner ungarischen Steuer anbieten?',
      answer: `
        <p>
          Ja, Leute, die Ihre Steuererklärung in Ungarn einreichen, können 1% ihrer Steuer staatlich für Hilfsorganisationen, in diesem Fall an die Stiftung Reménységháza zukommen lassen, die von unserer Gemeinde gegründet wurde.
          Der Haupttätigkeitsbereich der Stiftung ist Kinder- und Jugendschutz. 
          Die Stiftung verfügt über ein 90-jähriges Nutzungsrecht auf dem gesamten Grundstück,
          und kann somit auch zur Finanzierung der Baukosten beitragen.
          So, wenn der 1% der ungarischen Steuer der Stiftung zugeteilt wird, kann dadurch der Bau unterstützt
          werden. 
          Steuernummer der Stiftung Reménységháza: 19098801-1-07
             
        </p>`,
    },
    item5: {
      question: 'Wie werden meine Daten behandelt?',
      answer: `
        <p>
          Dessen Beschreibung erreichen Sie durch einen Klick auf unsere <a href="https://szbgy.hu/szepcel-datenschutz" target="_blank">Allgemeine Datenschutzerklärung</a>,
          bzw. bei Unterstützung auf <a href="https://szbgy.hu/einwilligung-zur-datenverwaltung-und-informationen-fur-unterstutzer" target="_blank">Datenverwaltung für Unterstützer</a>.
        </p>`,
    },
    item6: {
      question: 'Kann ich eine Rechnung von der Spende erhalten?',
      answer: `
        <p>
          Es kann keine Rechnung erstellt werden, dafür erstellen wir Ihnen jedoch eine Bescheinigung, die bei der Frage „Kann ich auch als Unternehmen Geld senden?” beschrieben wird.
        </p>`,
    },
    item7: {
      question: 'Wie kann ich monatliches Spenden stornieren?',
      answer: `
        <p>
          <ul>
            <li>
              Daueraufträge können bei Ihrer Bank geändert, bzw. gelöscht werden.
              Die meisten Banken bieten ihren Kunden die Möglichkeit eines Zugangs durch Internet, bzw. Smartphone-App zu ihrer Online-Banking an. 
            </li>
            <li>
              Ein endgültiges Absagen oder Pausieren einer regelmäßigen Spende durch Bankkarte kann mithilfe der Links von der Bestätigungsemail des Banksystems (SimplePay, PayPal), bzw. durch einen Antrag an die Email-Adresse der Baptisten-Gemeinde Székesfehérvár (baptistafehervar@gmail.com) eingeleitet werden.
            </li>
          </li>
        </p>`,
    },
    item8: {
      question: 'Mit welcher Bankkarte kann ich Geld senden?',
      answer: `
        <p>
          Das online Zahlungssystem von SimplePay und PayPal ermöglicht die Verwendung der Debit- und Kreditkarten von Mastercard/Maestro, VISA und VISA Electron (bei Electron nur mit Genehmigung der ausstellenden Bank), sowie von zur Internet-Zahlung geeigneten Webkarten.
        </p>`,
    },
    title: 'Häufig gestellte Fragen',
  },
  news: {
    continueReading: 'Mehr dazu',
    title: 'Aktuelle Berichte',
  },
  photos: {
    title: 'Galerie',
  },
  thanks: {
    title: 'Vielen Dank für Ihre Unterstützung unserer Bauarbeiten.',
    subtitle: 'Bitte, teilen Sie es, damit auch andere helfen können.',
    facebookShare: 'Teilen auf Facebook',
    aboutUs: 'über uns',
    back: 'zurück zur Seite Unterstützung',
    simplepay: {
      referenceNumber: 'SimplePay Referenznummer',
      orderId: 'Spenden-Identifizierungsnummer',
      date: 'Datum',
      successful: {
        title: 'Kartenprüfung erfolgreich.',
      },
      unsuccessful: {
        title: 'Transaktion war nicht erfolgreich.',
        subtitle: `
          Bitte, überprüfen Sie die angegebenen Daten auf Richtigkeit.
          Insofern alle Daten richtig angegeben wurden, und es trotzdem zur Ablehnung der Transaktion kommt, bitten wir Sie sich mit Ihrer Bank in Verbindung zu setzen.`,
      },
      timeout: {
        title: 'Abgebrochene Transaktion',
        subtitle: 'Die Zahlung wurde abgebrochen, oder wegen Zeitüberschreitung beendet!',
      },
    },
  },
  szepcel: {
    privacyPolicyUrl: 'https://szbgy.hu/szepcel-datenschutz',
    title: 'Szépcél',
  },
};

export default phrases;
