const phrases = {
  cookie: {
    more: 'Erfahren Sie mehr',
    text: 'Diese Website benutzt Cookies.',
  },
  main: {
    building: {
      building: 'Bauarbeiten',
      completed: 'Completed',
      idea: 'Idee',
      inProgress: 'In progress',
      movingIn: 'Übergabe',
      notStarted: 'Not started',
      painting: 'Innenbau',
      planning: 'Planung',
      support: 'Support our building project',
      title: 'Church construction',
    },
  },
};

export default phrases;
