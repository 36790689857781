import { SET_LANGUAGE } from 'redux-polyglot';
import { CLEAR_PAYPAL, SET_PAYPAL_OK } from './actions';


const initialState = {
  paypalOK: false,
};

export default function mainPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return initialState;
    case CLEAR_PAYPAL:
      return {
        ...state,
        paypalOK: false,
      };
    case SET_PAYPAL_OK:
      return {
        ...state,
        paypalOK: true,
      };
    default:
      return state;
  }
}
