/* eslint-env browser, node */
import moment from 'moment';
import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { getLocale } from 'redux-polyglot/dist/selectors';
import ReduxThunk from 'redux-thunk';

import { getPhrases } from './locale';
import reducer from './reducers';


const middlewares = [ReduxThunk];
if (process.env.REACT_APP_DEV) {
  middlewares.push(logger);
}

const persistConfig = {
  key: 'redux',
  migrate: createMigrate({
    0: state => {
      const { polyglot, ...rest } = state;
      return rest;
    },
  }),
  storage,
  version: 0,
  whitelist: ['banner', 'cookie'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

const enhancer = compose(
  applyMiddleware(...middlewares),
);

const defaultLanguage = 'HU';
const store = createStore(persistedReducer, {
  polyglot: { locale: defaultLanguage, phrases: getPhrases(defaultLanguage) },
}, enhancer);
persistStore(store);

store.subscribe(() => {
  const locale = getLocale(store.getState()).toLowerCase();
  if (moment.locale() !== locale) {
    moment.locale(locale);
  }
});


export default store;
