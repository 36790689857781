import PropTypes from 'prop-types';
import React from 'react';


class DynamicImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = { module: null };
  }

  componentDidMount() {
    const { loader } = this.props;
    loader().then(module => this.setState({ module: module.default }));
  }

  render() {
    const { module } = this.state;
    return module ? <this.state.module /> : null;
  }
}
DynamicImport.propTypes = {
  loader: PropTypes.func.isRequired,
};


const dynamicImport = loader => () => <DynamicImport loader={loader} />;

export default dynamicImport;
