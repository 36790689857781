const phrases = {
  nav: {
    about: 'About',
    goals: 'Goal',
    progress: 'Progress',
    donate: 'Donation',
    faq: 'FAQ',
    news: 'News',
  },
  cover: {
    title: 'See, I am doing a new thing! Now it springs up…  (Isa 43:19 NIV)',
    description: 'Szépcél is the building project of the Baptist Church in Székesfehévár.',
    subtitle: 'Szépcél is the building project of the <a href="https://szbgy.hu/en">Baptist Church in Székesfehévár</a>.',
    donate: 'Donate',
    video: 'Video',
    panorama: 'Panorama',
  },
  about: {
    title: 'About the project',
    content: `
      <p>
        Our community has been growing in number the past few years and for this reason we decided to start the renovation and extension of the church building in three phases.
      </p>
      <p>
        We hope that the building will serve as a modern home for our community for many years to come.
        Our current building has been used since 1981, however the number of people in our community has more than doubled since that time!
        Besides the extension of the prayer hall, the modernization of the infrastructure has also become essential.
      </p>
      <p>
        At the same time, the spiritual growth and mission work of the community are also considered to be important.
      </p>
      <p>
        We believe that reaching our goals as a church will be a blessing to us as well as others.
        The new building will enrich not only our community but also the city's spiritual life and Széchenyi Street's cityscape as well.
      </p>`,
  },
  goals: {
    title: 'Goals',
    item0: {
      title: '1. The Gate',
      content: `
        <p>In the first phase of the project, the extension of the entrance hall and the new front of the building will be completed.</p>
        <ul>
          <li>Beginning of project: 23 April 2019</li>
          <li>Finish: 5 June 2022</li>
          <li>Cost: 150 million HUF</li>
        </ul>
        <p>“the God of heaven will give us success. We his servants will start rebuilding…” (Neh 2:20 NIV)</p>
        <p>After years of planning, preparation and all kinds of difficulties we are grateful that the construction has begun.</p>`,
    },
    item1: {
      title: '2. The Tent',
      content: `
        <p>
          The prayer hall, with the capacity of accommodating 350 people, will be completed in the second phase.
          The present prayer hall will be extended towards the adjoining land, which has recently become the property of the church.
        </p>
        <ul>
          <li>Expected time of beginning: 2022</li>
          <li>Finish: 2023</li>
          <li>Appr. cost: ??? million HUF</li>
        </ul>
        <p>
          "Jerusalem will be a city without walls because of the great number of men and livestock in it.
          And I myself will be a wall of fire around it,’ declares the Lord, ’and I will be its glory within."
          (Zach 2:4-5 NIV)
        </p>`,
    },
    item2: {
      title: '3. The Court',
      content: `
        <p>
          The underground garage and the yard will be completed in the third phase of the project.
          The church building is situated in the city centre, where according to present law, parking is not possible even in the extended land,
          therefore building an underground garage is essential.
          The final step following the completion of the underground garage will be landscaping the grounds. 
        </p>
        <ul>
          <li>Expected beginning: 2024</li>
          <li>Finish: 2025</li>
          <li>Approximate cost: ??? million HUF</li>
        </ul>`,
    },
  },
  progress: {
    title: 'Progress',
  },
  donate: {
    title: 'Donation',
    content: `
      <p>
        As you can see this is a big undertaking and vision we have recieved from the Lord.
        If you feel led to give towards this wonderful project please review the options below and select your method of payment.
        No amount is too small, and we thank you in advance for your generosity!
      </p>
      <p>You can make your offerings in the following ways:</p>`,
    once: 'Single',
    emailPlaceholder: 'something@gmail.com',
    amountPlaceholder: 'Minimum amout: %{amount} Ft',
    optionalData: 'Optional information:',
    accept: `
      I accept the <a href="https://szbgy.hu/szepcel-privacy" target="_blank">Privacy Notice</a>
      and the <a href="https://szbgy.hu/data-handling-consent-and-conditions-for-donors" target="_blank">Terms Of Donation</a>.`,
    simplepay: {
      buttonTitle: 'Next',
      buttonSubtitle: 'to payment',
      logoUrl: 'http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf',
      logoTitle: 'SimplePay - On-line payment with bank card',
      logoAlt: 'SimplePay guide',
    },
    input: {
      firstName: 'First name',
      lastName: 'Last name',
      country: 'Country',
      zipCode: 'ZIP code.',
      city: 'City',
      address: 'Address',
    },
    transfer: {
      content: `
        <ul>
          <li><strong>Account holder:</strong></li>
          <li>Székesfehérvári Baptista Gyülekezet</li>
          <li><strong>Account number:</strong></li>
          <li>SWIFT: BCXHUHB</li>
          <li>IBAN: HU81 10918001-00000062-18340002</li>
        </ul>`,
      title: 'Wire transfer',
      subTitle: 'at your own bank',
    },
    footer: '',
    cancelled: 'Transaction cancelled',
  },
  counter: {
    title: 'Amount of donation up to now',
    percent: '<span>%{percent}%</span> of the total cost',
  },
  faq: {
    item0: {
      question: 'I have no PayPal but I would like to set a monthly transfer. What can I do?',
      answer: `
        <p>
          You can set a regular payment order through the financial institution in person at the bank,
          online, or through a mobile application. 
        </p>`,
    },
    item1: { question: '', answer: '' },
    item2: {
      question: 'Is it possible to transfer my donation from abroad? ',
      answer: `
        <p>
          Certainly, you can transfer your donation through your bank.
          Most banks have online or mobile applications available for clients to use.
        </p>
        <p>
          When transferring from abroad, please use the following identifier:
        </p>
        <p>
          <ul>
            <li>SWIFT: BCXHUHB</li>
            <li>IBAN: HU81 10918001-00000062-18340002</li>
          </ul>
        </p>`,
    },
    item3: {
      question: 'Can I make a transfer in Euros or dollars?',
      answer: `
        <p>
          Yes. In these cases your bank will credit the amount in HUF according to the current exchange rate.
        </p>`,
    },
    item4: { question: '', answer: '' },
    item5: {
      question: 'How is my data handled?',
      answer: `
        <p>
          You can reach our website <a href="https://szbgy.hu/szepcel-privacy" target="_blank">Privacy Policy</a>,
          and in case of a donation our <a href="https://szbgy.hu/data-handling-consent-and-conditions-for-donors" target="_blank">Terms Of Donation</a>
          by clicking on the links.
        </p>`,
    },
    item6: { question: '', answer: '' },
    item7: {
      question: 'How can I cancel the regular payment?',
      answer: `
        <p>
          <ul>
            <li>
              You can cancel your regular monthly payment through your bank.
              Most banks have online platform or mobile application available for clients to use instead of personal banking.
            </li>
            <li>
              Regular payment with bank card can be cancelled or suspended through the links given in the automatic messages sent by the bank system (SimplePay, PayPal),
              or you can hand your application to the email address of the Baptist Church of Székesfehérvár (baptistafehervar@gmail.com).
            </li>
          </li>
        </p>`,
    },
    item8: {
      question: 'What type of bank card can I use to donate?',
      answer: `
        <p>
          With SimplePay and PayPal you can use the following bank or credit cards:
          Mastercard/Maestro, VISA and VISA Electron (you can use Electron if it is permitted by the bank that issued it)
          and web cards for internet usage.
        </p>`,
    },
    title: 'Frequently Asked Questions',
  },
  news: {
    continueReading: 'more',
    title: 'Latest news',
  },
  photos: {
    seeMore: 'see more',
    title: 'Gallery',
  },
  thanks: {
    title: 'Thank you for supporting our building project.',
    subtitle: 'Please share so that others may help.',
    facebookShare: 'Share on Facebook',
    aboutUs: 'more about our commnity',
    back: 'back to donation site',
    simplepay: {
      referenceNumber: 'SimplePay reference number',
      orderId: 'Donation ID',
      date: 'Date',
      successful: {
        title: 'Successful card check.',
      },
      unsuccessful: {
        title: 'Unsuccessful transaction.',
        subtitle: `
          Please check the given data.
          If the datas you have given are correct please contact with your bank
          in order to observe the reasons of the rejection.`,
      },
      timeout: {
        title: 'Cancelled transaction',
        subtitle: 'You cancelled the payment or the time limit of the transaction has expired.',
      },
    },
  },
  szepcel: {
    privacyPolicyUrl: 'https://szbgy.hu/szepcel-privacy',
    title: 'Szépcél',
  },
};

export default phrases;
