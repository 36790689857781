/* eslint max-classes-per-file: ["error", 2] */
import PropTypes from 'prop-types';
import React from 'react';
import { Spinner } from 'reactstrap';
import request from '../graphql';
import connectPolyglot from './connectPolyglot';


const defaultFetchMore = ({
  cursor, locale, query, resultCallback, count = 20, ...params
}) => (
  request(
    query, {
      count,
      cursor,
      language: locale,
      ...params,
    },
  ).then(response => {
    const finalResponse = resultCallback(response);
    return {
      items: finalResponse.edges.map(e => e.node),
      cursor: finalResponse.pageInfo.endCursor,
      hasMore: finalResponse.pageInfo.hasNextPage,
    };
  }).catch(() => ({
    items: [],
    cursor: null,
    hasMore: false,
  }))
);

export { defaultFetchMore as fetchMore };


const GraphQLList = ({
  cursor, p, fetchMore, fetchParams, hasMore, Item, items, locale,
}) => {
  const [isLoading, setLoading] = React.useState(false);
  const [currentCursor, setCursor] = React.useState(cursor);
  const [currentHasMore, setHasMore] = React.useState(hasMore);
  const [currentItems, setItems] = React.useState(items);

  const loadMore = React.useCallback(() => {
    setLoading(true);
    fetchMore({
      ...fetchParams, cursor: currentCursor, locale,
    }).then(({
      cursor: newCursor, hasMore: newHasMore, items: newItems,
    }) => {
      setCursor(newCursor);
      setHasMore(newHasMore);
      setItems([...currentItems, ...newItems]);
    }).finally(() => setLoading(false));
  }, [currentCursor, currentItems, fetchMore, fetchParams, locale]);

  React.useEffect(() => {
    setCursor(cursor);
    setHasMore(hasMore);
    setItems(items);
  }, [cursor, fetchMore, fetchParams, hasMore, items, locale]);

  React.useEffect(() => {
    if (!isLoading && currentItems.length === 0 && currentHasMore) {
      // Load the initial data
      loadMore();
    }
  }, [currentHasMore, currentItems, isLoading, loadMore]);

  let button = null;
  if (isLoading) {
    button = (
      <button type="button" className="borderedButton" title="...">
        <Spinner size="sm" />
      </button>
    );
  } else if (currentHasMore && fetchMore) {
    button = (
      <button
        type="button"
        className="borderedButton"
        onClick={loadMore}
        title={p.t('sermons.loadMore')}
      >
        {p.t('sermons.loadMore')}
      </button>
    );
  }

  return (
    <ul className="graphqlList">
      {
        currentItems.map(item => (
          <Item
            key={item.id}
            item={item}
          />
        ))
      }
      {button}
    </ul>
  );
};
GraphQLList.propTypes = {
  cursor: PropTypes.string,
  fetchMore: PropTypes.func,
  fetchParams: PropTypes.object,
  hasMore: PropTypes.bool,
  Item: PropTypes.any.isRequired,
  items: PropTypes.array,
  locale: PropTypes.string,
  p: PropTypes.object.isRequired,
};
GraphQLList.defaultProps = {
  cursor: null,
  fetchMore: defaultFetchMore,
  fetchParams: {},
  hasMore: true,
  items: [],
};

export default connectPolyglot(GraphQLList);
