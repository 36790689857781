import { SET_LANGUAGE } from 'redux-polyglot';

import {
  PAUSE_AUDIO, PLAY_AUDIO, STOP_AUDIO, TOGGLE_AUDIO,
} from './actions';


const initialState = {
  isPlaying: false,
  src: null,
};


export default function mainPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
    case STOP_AUDIO:
      return initialState;
    case PLAY_AUDIO:
      return { isPlaying: true, src: action.src };
    case PAUSE_AUDIO:
      return { ...state, isPlaying: false };
    case TOGGLE_AUDIO:
      return { ...state, isPlaying: !state.isPlaying };
    default:
      return state;
  }
}
