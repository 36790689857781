import request from '../graphql';

export const FETCH_REQUESTED = 'NEXT_EVENT_FETCH_REQUESTED';
export const FETCH_SUCCESSFUL = 'NEXT_EVENT_FETCH_SUCCESSFUL';
export const FETCH_FAILED = 'NEXT_EVENT_FETCH_FAILED';
export const CHANGE_OTHER_LANGUAGE_URL = 'CHANGE_OTHER_LANGUAGE_URL';

const NEXT_EVENT_QUERY = `
query LiveStatus($language: Language!) {
  live {
    liveStatus {
      id
      title
    }
    liveUrl
  }
  nextEvent
  mainPage(language: $language) {
    ministriesRootPage {
      children(first: 1) {
        edges { node { id } }
      }
    }
    aboutUsRootPage {
      children(first: 1) {
        edges { node { id } }
      }
    }
  }
  allArticles(first: 1, language: $language) {
    edges { node { id } }
  }
  allSermons(first: 1, language: $language) {
    edges { node { id } }
  }
}`;

const fetchRequested = () => ({ type: FETCH_REQUESTED });
const fetchSuccessful = data => ({ type: FETCH_SUCCESSFUL, data });
const fetchFailed = err => ({ type: FETCH_FAILED, err: err || true });

export const changeOtherLanguageUrl = otherLanguageUrl => ({
  type: CHANGE_OTHER_LANGUAGE_URL, otherLanguageUrl,
});

export const fetchNextEvent = ({ locale }) => dispatch => {
  dispatch(fetchRequested());

  request(NEXT_EVENT_QUERY, { language: locale })
    .then(response => dispatch(fetchSuccessful({
      hasAboutUs: response.mainPage.aboutUsRootPage.children.edges.length > 0,
      hasArticles: response.allArticles.edges.length > 0,
      hasGroups: response.mainPage.ministriesRootPage.children.edges.length > 0,
      hasSermons: response.allSermons.edges.length > 0,
      liveStatus: response.live.liveStatus,
      liveUrl: response.live.liveUrl,
      nextEvent: response.nextEvent,
    })))
    .catch(err => dispatch(fetchFailed(err)));
};
