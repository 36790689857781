/* eslint-env browser, node */
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, withRouter } from 'react-router-dom';
import { getLocale } from 'redux-polyglot';

import { isSzepcel } from './site';
import DynamicImport from './utils/dynamic-import';


class ScrollToTopHelper extends React.Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    } else if (
      location !== prevProps.location
      && location.pathname === prevProps.location.pathname
      && location.hash === ''
    ) {
      // When staying on the main page, scroll smoothly
      document.documentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}
ScrollToTopHelper.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};
const ScrollToTop = withRouter(ScrollToTopHelper);

const DynamicSzBGyApp = DynamicImport(() => import('./main'));
const DynamicSzepcel = DynamicImport(() => import('./szepcel/index'));

const DomainRouter = () => {
  if (isSzepcel) {
    return <DynamicSzepcel />;
  }
  return <DynamicSzBGyApp />;
};

const App = ({ locale }) => (
  <BrowserRouter>
    <HelmetProvider>
      <ScrollToTop>
        <Helmet htmlAttributes={{ lang: locale.toLowerCase() }}>
          <meta httpEquiv="content-language" content={locale.toLowerCase()} />
        </Helmet>
        <Switch>
          <DomainRouter />
        </Switch>
      </ScrollToTop>
    </HelmetProvider>
  </BrowserRouter>
);
App.propTypes = {
  locale: PropTypes.string.isRequired,
};

export default connect(state => ({ locale: getLocale(state) }))(App);
