const phrases = {
  accessibility: {
    newWindow: '(opens in a new window)',
  },
  articles: {
    loadMore: 'Load more',
    title: 'Articles',
    url: 'articles',
  },
  banner: {
    ukraine: 'Humanitarian aid to Ukraine',
  },
  calendar: {
    loadMore: 'Load more',
    title: 'Events',
    url: 'events',
  },
  churchName: 'Baptist Church Székesfehérvár',
  cookie: {
    more: 'More info',
    text: 'We\'re using cookies.',
  },
  footer: {
    title: 'Church contacts',
    address: 'Address: HU-8000, Székesfehérvár Széchenyi út 26.',
    pastor: 'Pastor: Révész Lajos',
    accountNumber: 'Bank account number: Unicredit Bank 10918001-00000062-18340002',
    taxNumber: 'Tax number: 18503649-1-07',
    email: 'baptistafehervar@gmail.com',
  },
  header: {
    aboutUs: 'About us',
    articles: 'News',
    events: 'Events',
    groups: 'Ministries',
    liveSermon: 'Live stream',
    persons: 'Leaders',
    sermons: 'Sermons',
    support: 'Support us',
  },
  live: {
    nextService: 'Next service',
    days: 'day |||| days',
    hours: 'hour |||| hours',
    minutes: 'minute |||| minutes',
    seconds: 'second |||| seconds',
  },
  main: {
    aboutUs: {
      title: 'About us',
    },
    articles: {
      showMore: 'Older articles',
      title: 'News, articles',
    },
    building: {
      building: 'Building',
      completed: 'Completed',
      idea: 'Idea',
      inProgress: 'In progress',
      movingIn: 'Moving in',
      notStarted: 'Not started',
      painting: 'Interior works',
      planning: 'Planning',
      support: 'Support our building project',
      title: 'Church construction',
    },
    calendar: {
      showMore: 'More events',
      title: 'Events',
    },
    groups: {
      title: 'Ministries',
    },
    map: {
      address: '8000 Székesfehérvár Széchenyi utca 26.',
      clickToShow: 'Click to show map',
      iframeTitle: 'map',
      sermontimes: 'Sermons on Sundays at 10:00 AM',
      title: 'Join us!',
    },
    persons: {
      title: 'Leaders',
    },
    sermons: {
      showMore: 'show all',
      title: 'Recent English sermons',
    },
    title: {
      down: 'scroll down',
      lastSermon: 'The most recent English sermon',
      listen: 'listen',
      sermons: 'sermons',
      szepcel: 'Support our building project',
    },
  },
  meta: {
    description: 'Sermons, Live stream, Articles, Events, Ministries, Statement of faith.',
    sermonsDescription: 'Search and listen to past sermons from the previous years. Videos, audio recordings and sermon notes.',
  },
  notFound: {
    title: 'Page not found',
    text: '404 - The requested page is not found',
  },
  page: {
    days: {
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday',
    },
    events: 'Events:',
    facebookPage: 'Facebook page',
    moreInformation: 'More information:',
    photos: 'Pictures',
    sermonsTitle: 'Audio recordings',
    subpages: 'Groups',
  },
  sermons: {
    download: 'download',
    loadMore: 'Load more',
    play: 'play',
    search: 'Search...',
    title: 'Sermons',
    url: 'sermons',
  },
  szepcel: {
    title: 'Szépcél',
  },
};

export default phrases;
