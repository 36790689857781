/* eslint-env node */
import graphqlRequest from 'graphql-request';

const backendDomains = [
  'baptistafehervar.hu',
  'old.baptistafehervar.hu',
  'new.baptistafehervar.hu',
];

const request = async (query, variables) => {
  let url = null;
  if (process.env.REACT_APP_DEV) {
    url = 'http://localhost:8000/graphql';
  } else if (backendDomains.includes(document.location.hostname.toLocaleLowerCase())) {
    url = '/graphql'; // Use the server on the current domain
  } else {
    url = 'https://baptistafehervar.hu/graphql';
  }
  try {
    return await graphqlRequest(
      url,
      query.replace(new RegExp(/\s+/, 'g'), ' ').trim(),
      variables,
    );
  } catch (error) {
    if (error.response) {
      throw error.response.errors[0];
    } else {
      throw error;
    }
  }
};

export default request;
