import {
  CLOSE_LIGHTBOX, LIGHTBOX_CHANGE, OPEN_LIGHTBOX,
} from './actions';


const initialState = {
  currentUrls: null,
  isOpen: false,
  photoIndex: 0,
};


export default function mainPageReducer(state = initialState, action) {
  switch (action.type) {
    case CLOSE_LIGHTBOX:
      return initialState;
    case LIGHTBOX_CHANGE:
      if (!state.currentUrls.length) return initialState;
      return { ...state, photoIndex: action.index };
    case OPEN_LIGHTBOX:
      return {
        ...state, currentUrls: action.urls, isOpen: true, photoIndex: action.photoIndex,
      };
    default:
      return state;
  }
}
