import { ACCEPT_COOKIES } from './actions';


const initialState = {
  acceptedCookies: false,
};


export default function privacyReducer(state = initialState, action) {
  switch (action.type) {
    case ACCEPT_COOKIES:
      return { acceptedCookies: true };
    default:
      return state;
  }
}
