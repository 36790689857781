import {
  CHANGE_OTHER_LANGUAGE_URL, FETCH_FAILED, FETCH_REQUESTED, FETCH_SUCCESSFUL,
} from './actions';


const initialState = {
  data: null,
  isLoading: false,
  err: false,
  otherLanguageUrl: null,
};

export default function mainPageReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_REQUESTED:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_SUCCESSFUL:
      return {
        ...state,
        isLoading: false,
        data: action.data,
      };
    case FETCH_FAILED:
      return {
        ...state,
        isLoading: false,
        err: action.err,
      };
    case CHANGE_OTHER_LANGUAGE_URL:
      return {
        ...state,
        otherLanguageUrl: action.otherLanguageUrl,
      };
    default:
      return state;
  }
}
